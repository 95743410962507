.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 50px);
  padding: 25px;
  .HeaderTextEl1 {
    font-size: 1.2rem;
  }
}

.burgerMenuIcon {
  width: 30px;
}

.BurgerMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: 10px;
  top: 60px;
  background-color: #2f2d2e;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  width: 90px;
  height: 115px;
}

.Text {
  color: white;
}
