.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 200px);
  height: 80px;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 1.6rem;

  .HeaderTextEl1 {
    font-size: 1.4rem;
    margin: 0;
  }

  .HeaderNavigator {
    display: flex;
    width: 25%;
    justify-content: right;

    .HeaderTextEl {
      margin-right: 25px;
      font-size: 1.4rem;
      cursor: pointer;
      user-select: none;
    }
  }
}

button {
  background-color: transparent;
  border: none;
}

ul {
  display: flex;
  list-style-type: none;
}
