.ServicesWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;

  .ServicesHeader {
    display: flex;
    margin-bottom: 50px;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
  }

  .ServicesWrapper2 {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .Wrapper {
    display: flex;
    width: 95%;
    margin: 0 auto;
    gap: 20px;
  }

  .Service {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .HeaderImage {
      width: 100%;
      max-height: 280px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    .HeaderText {
      font-weight: bold;
      font-size: 1.1rem;
      padding: 15px;
    }

    .DescriptionText {
      padding: 15px;
    }
  }
}
